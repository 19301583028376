import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.css';
import App from './App';
import "font-awesome/css/font-awesome.css";
import "./styles/Ads.css"
import "./styles/Footer.css"
import reportWebVitals from './reportWebVitals';
import DataProvider from './redux/store'

ReactDOM.render(
  <React.StrictMode>
    <DataProvider>
      <App />
    </DataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
