const MYSELF = [
    {
        id: 1,
        image: "assets/images/TA2.PNG",
        name: "Tah$in Ahmed Badshah",
        category: "Creator of foodbyte",
        description_1: "Hi, this is Tahsin Ahmed Badshah. My vision about foodbyte is to make it world's largest online foodie community. Anyone can be a part of this and contribute to build this network largest and amazing. However, it's a beautiful platform for sharing one's happiness through his daily foodie activities. Also, one can participate upcoming events as well. The well-wisher can also donate us his valuable money to grow this platform.",
        description_2: "Oops! I didn’t introduce myself properly. Well, I was born at Dhaka, Bangladesh. I finished my school life at 2018 and got myself admitted to the University of Dhaka in 2018-2019 session, but hardly could cope-up with programming because my departmental studies weren't computer related. So, I could hardly focus on my departmental studies as well as seeking programming knowledge. But I was enthusiastic and addicted to computer robustly. As a result, my brain hit me under the hood after passing some months and advised me that I should hanker after my dream and my passion for what I was lived for. So, I dropped out from the University of Dhaka and got the green ticket to become a Computer Science student at the BRAC University on 15th September in 2019. To put in a nutshell, there is no better happiness than fulfilling one's own dream. That's it, a short story of my life.",
        description_3: "Last but not the least, I alone unable to spread this network and make it optimum beneficial for peoples’ welfare. So, I’m nothing without your best support and contribution, guys!",
        email: "support@foodbyte.xyz",
        phone: "+8801818832925"
    }
]

export default MYSELF;