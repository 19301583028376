import React from 'react'
import NoNotice from '../images/imageedit_1_8002155765.gif'

const NotFound = () => {
    return (
        <div className="position" style={{minHeight: 'calc(50vh - 100px)'}}>
                <img src={NoNotice} alt="NoNotice" className="w-100"/>
            {/* <h5 className="position-absolute text-secondary"
            style={{top: '50%', left: '50%', transform: 'translate(-50%, -70%)'}}>

            </h5> */}
        </div>
    )
}

export default NotFound
