import React from 'react';

const PrivacyItem = (props) => {
    return (
        <div>
            <b style={{cursor: "pointer", color: "#30115d"}} onClick={props.onMyselfSelect}> Privacy&Policy </b>       
        </div>
    );
}

export default PrivacyItem;