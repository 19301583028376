import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../redux/actions/authAction';
import { useParams, Link } from 'react-router-dom';
import Logo from "../images/foodbyte poster.png";
import Footer from "../components/footer/Footer";

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch();
    const { id, token } = useParams();  // Extract id and token from URL

    const handleSubmit = e => {
        e.preventDefault();
        if (password !== confirmPassword) {
            return alert("Passwords do not match");
        }

        // Pass both id and token along with the new password
        dispatch(resetPassword(id, token, password));
    }

    return (
        <div className="auth_page">
            <img className='logo' src={Logo} alt="Foodbyte"/>
            <div className='footer'>
               <Footer />
            </div>
            <form onSubmit={handleSubmit}>
                <h3 className="text-uppercase text-center mb-4" style={{color: "#30115d"}}><b>Reset Password</b></h3>

                <div className="form-group">
                    <label htmlFor="password"> <b>New Password</b> </label>
                    <input type="password" className="form-control" id="password" name="password"
                    onChange={(e) => setPassword(e.target.value)} value={password} style={{border: "2px solid #31005d"}} />
                </div>

                <div className="form-group">
                    <label htmlFor="confirmPassword"> <b>Confirm Password</b> </label>
                    <input type="password" className="form-control" id="confirmPassword" name="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} style={{border: "2px solid #31005d"}} />
                </div>

                <button type="submit" className="btn btn-dark w-100" disabled={!password || !confirmPassword}>
                    Reset Password
                </button>

                <p className="my-2">
                    <br />
                     If you don't need it, <Link to="/" style={{color: "#31005d", textDecoration: "none"}}> <b> Get back to login </b> </Link>
                 </p>                
            </form>
        </div>
    );
}

export default ResetPassword;
