import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getSuggestions } from '../../redux/actions/suggestionsAction'
import SearchFriend from './post_card/searchFriend'
import "../../styles/friendSuggestion.css"

const FriendSuggestion = () => {
    const { auth, suggestions } = useSelector(state => state)
    const dispatch = useDispatch()

    return (
        <div className="suggestion-header">
            {/* <UserCard user={auth.user} /> */} <br />

            <div className="suggestion_name d-flex justify-content-between align-items-center my-2">
                <h5> <b> Find your friends </b> </h5>
                {
                    !suggestions.loading &&
                    <i className="suggestion_reload fas fa-redo"
                    onClick={ () => dispatch(getSuggestions(auth.token)) } />
                }
            </div>

            <SearchFriend />
            
        </div>
    )
}

export default FriendSuggestion
