import React from 'react';
import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';

const AboutDetail = (props) => {
    return (
        <div>
            <Card style={{ marginTop: "10px", border: "none" }}>
                <CardImg className="img" top src={props.info.poster} alt={props.info.name} />
                <CardBody style={{ textAlign: "left" }}>
                    <CardTitle className='title'> <b> {props.info.title_1} </b> </CardTitle>
                    <CardText className='description'>
                        {props.info.description_1}
                    </CardText>
                    <br />

                    <CardTitle className='title'> <b> {props.info.title_2} </b> </CardTitle>
                    <CardText className='description'>
                        {props.info.description_2}
                    </CardText>
                    <br />

                    <CardTitle className='title'> <b> {props.info.title_3} </b> </CardTitle>
                    <CardText className='description'>
                        {props.info.description_3}
                    </CardText>
                    <br />

                    <CardTitle className='title'> <b> {props.info.title_4} </b> </CardTitle>
                    <CardText className='description'>
                        {props.info.description_4}
                    </CardText>
                    <br />

                    <CardTitle style={{fontSize: "25px", color: "#400079", textAlign: "center"}}> <b> Follow Foodbyte! </b> </CardTitle> <br />

                    <div className='followMe'>
                        <a href="https://www.facebook.com/foodbyteapp" target={"_blank"} rel="noreferrer" className='facebook'> <span className="fa fa-facebook fa-2x text-primary"> </span> </a>
                        <a href="https://www.instagram.com/foodbyteapp" target={"_blank"} rel="noreferrer" className='instagram'> <span className="fa fa-instagram fa-2x" style={{color: "rgb(255, 0, 106)"}}> </span> </a>
                        {/* <a href="https://twitter.com/ahmedinsiders" target={"_blank"} rel="noreferrer" className='twitter'> <span className="fa fa-twitter fa-2x text-primary"> </span> </a> */}
                        {/* <a href="https://www.linkedin.com/in/ahmedinsider/" target={"_blank"} rel="noreferrer" className='linkedIn'> <span className="fa fa-linkedin fa-2x text-primary"> </span> </a> */}
                    </div>

                </CardBody>
            </Card>
        </div>
    );
}

export default AboutDetail;