import React from 'react';

const TermsItem = (props) => {
    return (
        <div>
            <b style={{cursor: "pointer", color: "#30115d"}} onClick={props.onMyselfSelect}> Terms&Condition </b>       
        </div>
    );
}

export default TermsItem;