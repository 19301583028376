import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getSuggestions } from '../../redux/actions/suggestionsAction'
import Suggestions from './post_card/Suggestions'
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy'
import TermsCondition from './TermsCondition/TermsCondition'
import About from './About/About'

const RightSideBar = () => {
    const { auth, suggestions } = useSelector(state => state)
    const dispatch = useDispatch()

    return (
        <div className="mmt-2">
            {/* <UserCard user={auth.user} /> */} <br />

            <div className="d-flex justify-content-between align-items-center my-2">
                <h5 style={{color: "#31005d"}}> <b> Suggestions for you </b> </h5>
                {
                    !suggestions.loading &&
                    <i className="fas fa-redo" style={{cursor: 'pointer', marginRight: "45%"}}
                    onClick={ () => dispatch(getSuggestions(auth.token)) } />
                }
            </div>

            <Suggestions />

            <hr style={{backgroundColor: "#31005d", width: "90%", height: "5px"}}/>

            <div className='right_sidebar'>
                {/* <div className='d-flex'>
                    <div className='d-inline p-1 m-1'> <About /> </div>
                    <div className='d-inline p-1 m-1'> <Disclosure /> </div>                
                    <div className='d-inline p-1 m-1'> <PrivacyPolicy /> </div>
                </div> */}
                <div> <About /> </div>
                <div> <TermsCondition /> </div>                
                <div> <PrivacyPolicy /> </div>
            </div>
        </div>
    )
}

export default RightSideBar
