import React, { Component } from 'react';
import TERMS from './Myself';
import TermsItem from './TermsItem';
import TermsDetail from "./TermsDetail";
import { CardColumns, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';


class TermsCondition extends Component {
    state = {
        myself: TERMS,
        myselfSelected: null,
        modalOpen: false
    }

    onMyselfSelect = info => {
        this.setState({
            myselfSelected: info,
            modalOpen: !this.state.modalOpen
        });
    }

    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    render() {
        const menu = this.state.myself.map(item => {
            return (
                <TermsItem
                    info={item}
                    key={item.id}
                    onMyselfSelect={() => this.onMyselfSelect(item)}
                />
            );
        })

        let termsDetail = null;
        if (this.state.myselfSelected != null) {
            termsDetail = <TermsDetail
                info={this.state.myselfSelected}
                />
        }
        return (
            <div className="container">
                <div className="row">
                    <CardColumns>
                        {menu}
                    </CardColumns>
                    <Modal size="lg" isOpen={this.state.modalOpen}>
                        <ModalFooter>
                            <Button color="danger" onClick={this.toggleModal}> <b> X </b> </Button>
                        </ModalFooter>
                        <ModalBody>
                            {termsDetail}
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default TermsCondition;