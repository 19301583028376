import React from 'react';
import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';

const FooterDetail = (props) => {
    return (
        <div>
            <Card style={{ marginTop: "10px", border: "none" }}>
                <CardImg className="img" top src={props.info.image} alt={props.info.name} />
                <CardBody style={{ textAlign: "left" }}>
                    <CardTitle className='title'> <b> {props.info.name} </b> </CardTitle>
                    <CardText className='subTitle'>
                        <b> {props.info.category} </b>
                    </CardText>
                    <CardText className='description'>
                        {props.info.description_1}
                    </CardText>
                    {/* <CardImg src={props.info.poster} alt="Foodbyte" /> */}
                    <CardText className='description'>
                        {props.info.description_2}
                    </CardText>
                    <CardText className='description'>
                        {props.info.description_3}
                    </CardText>

                    <br /> <br />

                    <div className="myselfDetail">
                        <CardTitle className="b1"> <b> For support </b> </CardTitle>
                        <CardText className="b2">
                            <b style={{color: "yellow"}}> Email: </b> <b style={{color: "white"}}> {props.info.email} </b>
                        </CardText>
                    </div>

                    <br /> <br />

                    <CardTitle style={{fontSize: "25px", color: "#400079", textAlign: "center"}}> <b> Follow me! </b> </CardTitle> <br />

                    <div className='followMe'>
                        <a href="https://www.facebook.com/ahmedinsider/" target={"_blank"} rel="noreferrer" className='facebook'> <span className="fa fa-facebook fa-2x text-primary"> </span> </a>
                        <a href="https://www.instagram.com/ahmedinsider/" target={"_blank"} rel="noreferrer" className='instagram'> <span className="fa fa-instagram fa-2x" style={{color: "rgb(255, 0, 106)"}}> </span> </a>
                        <a href="https://twitter.com/ahmedinsiders" target={"_blank"} rel="noreferrer" className='twitter'> <span className="fa fa-twitter fa-2x text-primary"> </span> </a>
                        <a href="https://www.linkedin.com/in/ahmedinsider/" target={"_blank"} rel="noreferrer" className='linkedIn'> <span className="fa fa-linkedin fa-2x text-primary"> </span> </a>
                    </div>

                </CardBody>
            </Card>
        </div>
    );
}

export default FooterDetail;