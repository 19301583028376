import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../redux/actions/authAction';
import Footer from "../components/footer/Footer";
import Logo from "../images/foodbyte poster.png";
import { Link } from 'react-router-dom';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(forgotPassword(email));
    }

    return (
        <div className="auth_page">
            <img className='logo' src={Logo} alt="Foodbyte"/>
            <div className='footer'>
                <Footer />
            </div>

            <form onSubmit={handleSubmit}>
                <h3 className="text-uppercase text-center mb-4" style={{color: "#30115d"}}><b>Forgot Password</b></h3>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1"> <b> Email </b> </label>
                    <input type="email" className="form-control" id="exampleInputEmail1" name="email"
                    aria-describedby="emailHelp" onChange={(e) => setEmail(e.target.value)} value={email} style={{border: "2px solid #31005d"}} />
                </div>

                <button type="submit" className="btn btn-dark w-100" disabled={!email}>
                    Submit
                </button>

                <p className="my-2">
                    <br />
                    Got a great memory? <Link to="/" style={{color: "#31005d", textDecoration: "none"}}> <b> Get back to login </b> </Link>
                </p>
            </form>
        </div>
    );
}

export default ResetPassword;
