import React from "react";
import { useSelector } from 'react-redux'
import {Scrollbars} from "react-custom-scrollbars-2";
import UserCard from "../../UserCard";
import FollowBtn from "../../FollowBtn";
import LoadIcon from '../../../images/loading.gif';
import "../../../styles/friendSuggestion.css";

const SearchFriend = () => {
    const { suggestions } = useSelector(state => state)
    return (
        <div className="suggestion">
            <Scrollbars className="scrollbars_suggestions">
            {
                suggestions.loading
                ? <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" style={{height: "70px", width: "70px"}} />
                : <div className="suggestions">
                {
                        suggestions.users.map(user => (
                            <UserCard key={user._id} user={user} style={{textDecoration: "none"}}>
                                <FollowBtn user={user} />
                            </UserCard>
                        ))
                    }
                </div>
            }
            </Scrollbars>
        </div>
    )
}

export default SearchFriend;