import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { login } from '../redux/actions/authAction'
import { useDispatch, useSelector } from 'react-redux'
import Footer from "../components/footer/Footer"
import Logo from "../images/foodbyte poster.png"

const Login = () => {
    const initialState = { email: '', password: '' }
    const [userData, setUserData] = useState(initialState)
    const { email, password } = userData

    const [typePass, setTypePass] = useState(false)

    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if(auth.token) history.push("/")
    }, [auth.token, history])

    const handleChangeInput = e => {
        const { name, value } = e.target
        setUserData({...userData, [name]:value})
    }

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(login(userData))
    }

    return (
        <div className="auth_page">
            <img className='logo' src={Logo} alt="Foodbyte"/>
            <div className='footer'>
                {/* <h1 className="text-center mb-4"> foodbyte </h1>
                <h6> Sharing Happiness! </h6> */}
                <Footer />
            </div>

            <form onSubmit={handleSubmit}>
                <h3 className="text-uppercase text-center mb-4" style={{color: "#30115d"}}><b>Sign in</b></h3>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1"> <b> Email </b> </label>
                    <input type="email" className="form-control" id="exampleInputEmail1" name="email"
                    aria-describedby="emailHelp" onChange={handleChangeInput} value={email} style={{border: "2px solid #31005d"}} />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputPassword1"> <b> Password </b> </label>

                    <div className="pass">
                        
                        <input type={ typePass ? "text" : "password" } 
                        className="form-control" id="exampleInputPassword1"
                        onChange={handleChangeInput} value={password} name="password" style={{border: "2px solid #31005d"}} />

                        <small onClick={() => setTypePass(!typePass)}>
                            <b> {typePass ? 'Hide' : 'Show'} </b>
                        </small>
                    </div>
                   
                </div>
                
                <button type="submit" className="btn btn-dark w-100"
                disabled={email && password ? false : true}>
                    Login
                </button>

                <p className="my-2">
                    <br />
                    <Link to="/forgot_password" style={{color: "#31005d", textDecoration: "none"}}> <b> Forgot Password </b> </Link>
                </p>

                <p className="my-2">
                    <br />
                    You don't have an account? <Link to="/register" style={{color: "#31005d", textDecoration: "none"}}> <b> Register now </b> </Link>
                </p>
            </form>
        </div>
    )
}

export default Login
