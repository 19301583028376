import React from "react";
import FriendSuggestion from "../components/home/friendSuggestion";

const FindPeople = () => {
    return (
        <div>
            <FriendSuggestion />
        </div>
    )
}

export default FindPeople
