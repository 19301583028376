import React from 'react';

const FooterItem = (props) => {
    return (
        <div className='copyright'>
            {/* <b> Copyright 2022 &copy; </b> <b className='myself' onClick={props.onMyselfSelect}> Cyberengine Corporation </b> */}
            <b> Copyright &copy; </b> <a className="cyberengine" href='https://www.cyberengine.org' target="_blank" rel="noreferrer"> <b> Cyberengine </b> </a>       
        </div>
    );
}

export default FooterItem;