import React, { Component } from 'react';
import PRIVACY from './Myself';
import PrivacyItem from './PrivacyItem';
import PrivacyDetail from "./PrivacyDetail";
import { CardColumns, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';


class PrivacyPolicy extends Component {
    state = {
        myself: PRIVACY,
        myselfSelected: null,
        modalOpen: false
    }

    onMyselfSelect = info => {
        this.setState({
            myselfSelected: info,
            modalOpen: !this.state.modalOpen
        });
    }

    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    render() {
        const menu = this.state.myself.map(item => {
            return (
                <PrivacyItem
                    info={item}
                    key={item.id}
                    onMyselfSelect={() => this.onMyselfSelect(item)}
                />
            );
        })

        let privacyDetail = null;
        if (this.state.myselfSelected != null) {
            privacyDetail = <PrivacyDetail
                info={this.state.myselfSelected}
                />
        }
        return (
            <div className="container">
                <div className="row">
                    <CardColumns>
                        {menu}
                    </CardColumns>
                    <Modal size="lg" isOpen={this.state.modalOpen}>
                        <ModalFooter>
                            <Button color="danger" onClick={this.toggleModal}> <b> X </b> </Button>
                        </ModalFooter>
                        <ModalBody>
                            {privacyDetail}
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;