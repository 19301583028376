import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { checkImage } from '../../utils/imageUpload'
import { GLOBALTYPES } from '../../redux/actions/globalTypes'
import { updateProfileUser } from '../../redux/actions/profileAction'

const EditProfile = ({setOnEdit}) => {
    const initState = {
        fullname: '', email: '', mobile: '', gender: '', dob: '', address: '', website: '', story: ''
    }
    const [userData, setUserData] = useState(initState)
    const { fullname, email, mobile, gender, dob, address, website, story } = userData

    const [avatar, setAvatar] = useState('')

    const { auth, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        setUserData(auth.user)
    }, [auth.user])


    const changeAvatar = (e) => {
        const file = e.target.files[0]

        const err = checkImage(file)
        if(err) return dispatch({
            type: GLOBALTYPES.ALERT, payload: {error: err}
        })

        setAvatar(file)
    }

    const handleInput = e => {
        const { name, value } = e.target
        setUserData({ ...userData, [name]:value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(updateProfileUser({userData, avatar, auth}))
    }

    return (
        <div className="edit_profile">
            <form onSubmit={handleSubmit}>
                <button className="btn btn-danger" onClick={() => setOnEdit(false)}> <b> X </b> </button>
                <div className="info_avatar">
                    <img src={avatar ? URL.createObjectURL(avatar) : auth.user.avatar} 
                    alt="avatar" style={{filter: theme ? 'invert(1)' : 'invert(0)'}} />
                    <span>
                        <i className="fas fa-camera"/>
                        <p><b>Change</b></p>
                        <input type="file" name="file" id="file_up"
                        accept="image/*" onChange={changeAvatar} />
                    </span>
                </div>

                <div className="form-group">
                    <label htmlFor="fullname"><b>Full Name</b></label>
                    <div className="position-relative">
                        <input type="text" className="form-control" id="fullname"
                        name="fullname" value={fullname} style={{border: "2px solid #31005d"}} onChange={handleInput} />
                        <small className="text-danger position-absolute"
                        style={{top: '50%', right: '5px', transform: 'translateY(-50%)'}}>
                            {fullname.length}/25
                        </small>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="email"><b>Email</b></label>
                    <input type="text" name="email" value={email}
                    className="form-control" style={{border: "2px solid #31005d"}} onChange={handleInput} />
                </div>

                <div className="form-group">
                    <label htmlFor="mobile"><b>Mobile</b></label>
                    <input type="number" name="mobile" value={mobile}
                    className="form-control" style={{border: "2px solid #31005d"}} onChange={handleInput} />
                </div>

                <label htmlFor="gender"><b>Gender</b></label>
                <div className="input-group-prepend px-0 mb-4">
                    <select name="gender" id="gender" value={gender}
                    className="custom-select text-capitalize"
                    style={{border: "2px solid #31005d"}}
                    onChange={handleInput}>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="dob"><b>Date of Birth</b></label>
                    <input type="date" id="dob" name="dob" value={dob}
                    className="form-control" style={{border: "2px solid #31005d"}} onChange={handleInput} />
                </div>

                <div className="form-group">
                    <label htmlFor="address"><b>Address</b></label>
                    <input type="text" name="address" value={address}
                    className="form-control" style={{border: "2px solid #31005d"}} onChange={handleInput} />
                </div>

                <div className="form-group">
                    <label htmlFor="website"><b>Website</b></label>
                    <input type="text" name="website" value={website}
                    className="form-control" style={{border: "2px solid #31005d"}} onChange={handleInput} />
                </div>

                <div className="form-group">
                    <label htmlFor="story"><b>Story</b></label>
                    <textarea name="story" value={story} cols="30" rows="4"
                    className="form-control" style={{border: "2px solid #31005d"}} onChange={handleInput} />

                    <small className="text-danger d-block text-right">
                        {story.length}/100
                    </small>
                </div>

                <button className="btn w-100" type="submit" style={{backgroundColor: "#31005d", color: "yellow"}}> <b> Save </b> </button>
            </form>
        </div>
    )
}

export default EditProfile
