import React from 'react'

const LoadMoreBtn = ({result, page, load, handleLoadMore}) => {
    return (
        <>
            {
                result < 9 * (page - 1) ? '' :

                !load && <button className="btn btn-dark mx-auto d-block" style={{backgroundColor: "#20003c", color: "yellow", marginBottom: "12%"}}
                onClick={handleLoadMore}>
                    Load more
                </button>
            }
            
        </>
    )
}

export default LoadMoreBtn
