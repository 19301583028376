import React from 'react'

const ContactUs = () => {
    return (
        <div>
            <iframe className='ContactUs' title="contactFrame" src="https://docs.google.com/forms/d/e/1FAIpQLSf22-SpM0m9RxThnRToNoqLLKvhM98mUeEK4xbHXlZiX7BWhA/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    )
}

export default ContactUs
