import React from 'react'
import { Link } from 'react-router-dom'
import Menu from './Menu'
import Search from './Search'
import Logo from "../../images/cat.png"

const Header = () => {

    return (
        <div className="header">
            <nav className="navbar navbar-expand-lg navbar-light justify-content-between align-middle">
                <Link to="/" className="logo">
                    <h1 className="navbar p-0 m-0"
                    onClick={() => window.scrollTo({top: 0})}>
                        <img className="imgLogo" src={Logo} alt="Logo"/> foodbyte
                    </h1>
                </Link>
                <Search />
                <Menu />
            </nav>
        </div>
    )
}

export default Header
